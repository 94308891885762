import * as React from 'react';
import Span from 'styleguide/components/Span/Span';
import Modal from 'styleguide/components/Modal/Modal';
import Grid from 'styleguide/components/Grid/Grid';
import Loader from 'styleguide/components/Loader/Loader';
import startCase from 'lodash-es/startCase';

const ReorderModal = ({ action }) => (
  <Modal shown>
    <Grid.Container className="h-36 flex items-center">
      <Grid>
        <Grid.Row>
          <Grid.Col sm={9}>
            <Span className="text-xl lg:text-4xl">{`${startCase(action)} in progress...`}</Span>
          </Grid.Col>
          <Grid.Col sm={3}>
            <Loader className="hidden sm:block" size="xl" />
            <Loader className="sm:hidden" size="lg" />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Grid.Container>
  </Modal>
);

export default ReorderModal;
