import * as React from 'react';
import A from 'styleguide/components/Links/A';
import Button from 'styleguide/components/Button/Button';
import { formatMoney } from 'utils/money';
import { OrderBasic } from 'api/orders/types';
import Chip from 'styleguide/components/Chip/Chip';
import OrderMenu from '../OrderMenu/OrderMenu';
import IfTabletOrDesktop from 'styleguide/components/Conditional/IfTabletOrDesktop';
import IfMobile from 'styleguide/components/Conditional/IfMobile';
import { orderStateToColor } from 'utils/orderStateMap';
import DateTimeZone from 'styleguide/components/DateTimeZone/DateTimeZone';

interface HistoryItem {
  order: OrderBasic;
  handleReorder: (orderNumber: string) => void;
}

const nonReorderStates: string[] = ['Canceled', 'Quote', 'Returned/Refunded'];

const OrderHistoryItemDesktop = ({ order, ...props }: HistoryItem) => (
  <div className="my-2 flex items-center rounded-lg border border-solid border-neutral-200 p-4 lg:p-6 -md:p-4">
    <div
      className="grid w-full items-center gap-y-2 text-center font-hvMedium md:grid-flow-col
    md:grid-rows-2 lg:grid-flow-row lg:grid-cols-5 lg:grid-rows-none -md:grid-cols-2"
    >
      <A
        color="black"
        underline="none"
        href={`/order-details/?order_number=${order.number}&auth_token=${order.authenticationToken}`}
        targetBlank
        className="justify-self-start !font-hvMedium lg:pl-8 -md:pl-2"
      >
        #{order.number}
      </A>
      <div className="justify-self-start ">
        <Chip
          title={order.customerState}
          textColor={orderStateToColor[order.customerState]?.textColor || 'text-neutral-50'}
          backgroundColor={orderStateToColor[order.customerState]?.backgroundColor || 'bg-neutral-400'}
        />
      </div>
      <DateTimeZone
        date={order.completedAt}
        className="justify-self-start text-left -md:hidden"
        fnsFormat="MMMM do, yyyy"
      />
      <div className="justify-self-start -md:hidden">{formatMoney(order.total)}</div>
      <div className="flex self-center justify-self-end md:row-span-2 lg:row-span-1 lg:self-start">
        {order.canClone && !nonReorderStates.includes(order.customerState) ? (
          <Button
            className={order.canClone ? '' : 'hidden'}
            color="blue"
            onClick={() => {
              props.handleReorder(order.number);
            }}
            size="sm"
            type="button"
          >
            Order Again
          </Button>
        ) : (
          <div className="min-w-[116px]" />
        )}
        <Button.AsNativeLink
          buttonType="link"
          color="dark"
          outline
          target={`/order-details/?order_number=${order.number}&auth_token=${order.authenticationToken}`}
          size="sm"
          className="ml-6 -md:ml-3"
        >
          Order Details
        </Button.AsNativeLink>
        <OrderMenu order={order} />
      </div>
    </div>
  </div>
);

const OrderHistoryItemMobile = ({ order, ...props }: HistoryItem) => (
  <div className="my-2 flex flex-col rounded-lg border border-solid border-neutral-200 p-2">
    <div className="flex justify-between">
      <Chip
        title={order.customerState}
        textColor={orderStateToColor[order.customerState]?.textColor || 'text-neutral-50'}
        backgroundColor={orderStateToColor[order.customerState]?.backgroundColor || 'bg-neutral-400'}
      />
      <OrderMenu order={order} />
    </div>
    <div className="mt-3 flex justify-between">
      <A
        color="black"
        underline="none"
        href={`/order-details/?order_number=${order.number}&auth_token=${order.authenticationToken}`}
        targetBlank
        className="!font-hvMedium !text-base"
      >
        #{order.number}
      </A>
      <div className="font-hvMedium">{formatMoney(order.total)}</div>
    </div>
    <DateTimeZone
      date={order.completedAt}
      className="mt-3 self-end font-hvMedium text-sm text-gray-500"
      fnsFormat="MMMM do, yyyy"
    />
    <div className="mt-3 flex justify-between">
      {order.canClone && (
        <Button
          className={order.canClone ? 'mr-6 w-[47%] -md:mr-3' : 'hidden'}
          color="blue"
          onClick={() => {
            props.handleReorder(order.number);
          }}
          size="sm"
          type="button"
        >
          Order Again
        </Button>
      )}
      <Button.AsNativeLink
        buttonType="link"
        color="dark"
        outline
        target={`/account/orders/${order.number}/tracking`}
        size="sm"
        className="w-[47%]"
      >
        Track Order
      </Button.AsNativeLink>
    </div>
  </div>
);

const OrderHistoryItem = ({ order, ...props }: HistoryItem) => (
  <>
    <IfTabletOrDesktop className="">
      <OrderHistoryItemDesktop order={order} {...props} />
    </IfTabletOrDesktop>
    <IfMobile>
      <OrderHistoryItemMobile order={order} {...props} />
    </IfMobile>
  </>
);

export default OrderHistoryItem;
