import * as React from 'react';
import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconVerticalDots = ({
  title = 'Vertical Dots',
  color = 'mediumGray',
  viewBoxWidth = 24,
  viewBoxHeight = 24,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, color, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <path
      fill="#323232"
      d="M12 7.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm0 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm0 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
    />
  </SvgIcon>
);

export default IconVerticalDots;
