import * as React from 'react';
import A from 'styleguide/components/Links/A';
import EmptyBox from 'assets/images/emptybox.png';
import Image from 'styleguide/components/Image/Image';

const EmptyPage = () => (
  <div className="flex flex-col items-center justify-center">
    <Image className="" alt="Empty box" url={EmptyBox} width={192} height={212} />
    <p className="mt-10 font-hvBold text-2xl">You don’t have any orders</p>
    <p className="!mt-0 font-hvMedium">
      Please{' '}
      <A href="/products" color="black" underline="always">
        click here
      </A>{' '}
      to select a product
    </p>
  </div>
);

export default EmptyPage;
